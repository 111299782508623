import styled from 'styled-components';

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 500;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ModalInner = styled.div`
	position: relative;
	width: 100%;
	max-width: 1040px;
	margin: 0 16px;
`;

const ModalCross = styled.button`
	position: absolute;
	top: -56px;
	right: 0;
	height: 56px;
	width: 56px;
	background-color: var(--primaryForeground);
	padding: 12px;
	cursor: pointer;
	border: none;
`;

export { ModalWrapper, ModalInner, ModalCross };
