import React, { useEffect } from 'react';
import SVG from '../SVG';
import { ModalWrapper, ModalInner, ModalCross } from './index.styled';

type ModalProps = {
	children: React.ReactNode
	show: boolean
	setShow: (arg:boolean) => void
};

const Modal = ({ children, show, setShow }: ModalProps) => {

	const handleKeyDown = event => {
		if (event.key === 'Escape') {
			setShow(false);
		}
	};

	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden';
			document.body.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.body.style.overflowY = 'scroll';
			document.body.style.overflowX = 'hidden';
			document.body.removeEventListener('keydown', handleKeyDown);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	return (
		<>
			{show && (
				<ModalWrapper onClick={() => setShow(false)}>
					<ModalInner>
						<ModalCross tabIndex={0} onClick={() => setShow(false)}>
							<SVG name="cross" />
						</ModalCross>
						{children}
					</ModalInner>
				</ModalWrapper>
			)}
		</>
	);
};

export default Modal;
