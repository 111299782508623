import React from 'react';
import { PlayButton__Button } from './index.styled';
import { pushEvent } from '../../../lib/tracking';

interface PlayButtonProps {
	onClick: React.Dispatch<React.SetStateAction<boolean>>;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
	ariaLabel: string;
}

const PlayButton = ({
	onClick,
	dataGACategory,
	dataGAAction,
	dataGALabel,
	ariaLabel,
}: PlayButtonProps) => {
	const handleClick = event => {
		pushEvent(event);
		onClick(event);
	};

	return (
		<PlayButton__Button
			onClick={handleClick}
			data-ga-action={dataGAAction}
			data-ga-category={dataGACategory}
			data-ga-label={dataGALabel}
			aria-label={ariaLabel}
		/>
	);
};

export default PlayButton;
