import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import Head from 'next/head';
import Breadcrumbs from '../../atoms/Breadcrumbs';
import CustomImage from '../../atoms/CustomImage';
import Collage from '../../molecules/Collage';
import preloadHeroImage from '../../../styles/tools/preloadHeroImage';
import SVG from '../../atoms/SVG';
import LinkStandard from '../../atoms/LinkStandard';
import Modal from '../../atoms/Modal';
import PlayButton from '../../atoms/PlayButton';
import YouTubeEmbed from '../../atoms/YouTubeEmbed';
import Styled from './index.styled';

type BreadcrumbTypes = {
	id: string;
	url: string;
	title: string;
	dataGAAction: string;
	dataGALabel: string;
	dataGACategory: string;
};

type ImageTypes = {
	src: string;
	alt: string;
	imageType?: string;
};

type CtaTypes = {
	text: string;
	link: LinkTypes;
};

type LinkTypes = {
	id?: string;
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type EmbedTypes = {
	youtubeEmbed: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type HeroTypes = {
	title: string;
	para: string;
	para2?: string;
	para3?: string;
	links?: Array<LinkTypes>;
	location?: string;
	space?: string;
	tick?: string;
	offer?: string;
	breadcrumbs: Array<BreadcrumbTypes>;
	image?: ImageTypes;
	embed?: EmbedTypes;
	svg?: string;
	collage?: Array<ImageTypes>;
	cta?: CtaTypes;
	hideBoxShadow?: boolean;
	anchorId?: string;
};

const renderLinkItem = (link: LinkTypes) => (
	<Styled.Hero__LinkItem key={link.id}>
		<LinkStandard {...link} />
	</Styled.Hero__LinkItem>
);

const Hero = ({
	title,
	para,
	para2,
	para3,
	links,
	location,
	space,
	tick,
	offer,
	breadcrumbs,
	image,
	embed,
	collage,
	svg,
	cta,
	hideBoxShadow,
	anchorId,
}: HeroTypes) => {
	const isCollage = collage && collage.length > 0;
	const showBoxShadowForDesktop = isCollage && !hideBoxShadow;
	const showBoxShadowForMobile = !hideBoxShadow;
	const theme = useContext(ThemeContext);
	const fill = theme?.primaryAccent;
	const hasSvg = svg !== undefined && true;

	const [show, setShow] = useState(false);

	return (
		<>
			<Styled.Hero
				showBoxShadowForDesktop={showBoxShadowForDesktop}
				showBoxShadowForMobile={showBoxShadowForMobile}
				id={anchorId || ''}
			>
				<Head>
					{image && preloadHeroImage(image.src, image.imageType)}
					{isCollage && collage?.map(item => preloadHeroImage(item.src, item.imageType))}
				</Head>

				<Styled.Hero__Inner iscollage={isCollage} hassvg={hasSvg}>
					<Styled.Hero__ResponsiveContainer hassvg={hasSvg}>
						{breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} isHero />}

						<Styled.Hero__Heading as="h1">
							<Styled.Hero__HeadingHighlight as="span">
								{title}
							</Styled.Hero__HeadingHighlight>
						</Styled.Hero__Heading>
						<Styled.Hero__Body as="p">{para}</Styled.Hero__Body>
						{para2 && <Styled.Hero__Body as="p">{para2}</Styled.Hero__Body>}
						{para3 && <Styled.Hero__Body as="p">{para3}</Styled.Hero__Body>}
						{links && (
							<>
								<Styled.Hero__BodyHeading>
									Find out more about:
								</Styled.Hero__BodyHeading>

								<Styled.Hero__LinkList>
									{links.map(renderLinkItem)}
								</Styled.Hero__LinkList>
							</>
						)}

						{location && (
							<Styled.Hero__Info>
								{' '}
								<SVG name="pointer" /> {location}
							</Styled.Hero__Info>
						)}

						{space && (
							<Styled.Hero__Info>
								{' '}
								<SVG name="building" /> {space}
							</Styled.Hero__Info>
						)}

						{tick && (
							<Styled.Hero__Info>
								{' '}
								<SVG name="tick" /> {tick}
							</Styled.Hero__Info>
						)}

						{offer && (
							<Styled.Hero__Info>
								{' '}
								<SVG name="offer" /> {offer}
							</Styled.Hero__Info>
						)}

						{cta && (
							<Styled.Hero__CtaContainer>
								<Styled.Hero__CtaContainerText as="p">
									{cta.text}
								</Styled.Hero__CtaContainerText>
								<LinkStandard {...cta.link} testProp="hello" />
							</Styled.Hero__CtaContainer>
						)}
					</Styled.Hero__ResponsiveContainer>

					{collage && (
						<Styled.Hero__CollageContainer>
							<Collage collage={collage} isHero />
						</Styled.Hero__CollageContainer>
					)}

					{svg && (
						<Styled.Hero__SvgContainer>
							<Styled.Hero__svgContainerInner>
								<SVG name={svg} fill={fill} aria-hidden="true" />
							</Styled.Hero__svgContainerInner>
						</Styled.Hero__SvgContainer>
					)}

					{image && !embed && !svg && (
						<Styled.Hero__ImageContainer>
							<CustomImage {...image} width="300" height="210" />
						</Styled.Hero__ImageContainer>
					)}

					{image && embed && (
						<Styled.Hero__ImageContainer>
							<CustomImage {...image} width="300" height="210" />
							<Styled.PlayButtonWrapper>
								<PlayButton
									onClick={() => setShow(true)}
									dataGACategory={embed.dataGACategory}
									dataGAAction={embed.dataGAAction}
									dataGALabel={embed.dataGALabel}
									ariaLabel="play-hero-video"
								/>
							</Styled.PlayButtonWrapper>
						</Styled.Hero__ImageContainer>
					)}
				</Styled.Hero__Inner>
			</Styled.Hero>

			{image && embed && (
				<Modal show={show} setShow={setShow}>
					<YouTubeEmbed embedLink={embed.youtubeEmbed} />
				</Modal>
			)}
		</>
	);
};

export default Hero;
