
import styled, { css } from 'styled-components';
import { gap } from '../../../styles/settings/spacing';
import { hover } from '../../../styles/tools/media';
import { smBodyRegular } from '../Typography/index.styled';

type BreadcrumbProps = {
	isHero: boolean;
};

const Breadcrumbs = styled.ol`
	margin-bottom: ${gap[8]};
	padding-left: ${gap[0]};
	list-style-type: none;
`;

const Breadcrumbs__Item = styled(smBodyRegular) <BreadcrumbProps>`
	position: relative;
	display: inline-block;

	&::after {
		content: '/';
		padding-right: ${gap[4]};
		padding-left: ${gap[4]};

		${props => props.isHero && css`
			background-color: var(--primaryForeground);
			color: var(--white);
			padding-bottom: ${gap[4]};
		`}

		${props => !props.isHero && css`
			color: var(--primaryForeground);
		`}
	}

	&:last-child::after {
		display: none;
	}



`;

const Breadcrumbs__Link = styled.a<BreadcrumbProps>`
	position: relative;
	padding-bottom: ${gap[4]};
	text-decoration: none;

	${props => !props.isHero && css`
		color: var(--primaryForeground);

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 2px;
			background-color: var(--primaryAccent);
			transition: width 0.3s ease-in-out;
		}

		&:active::before,
		&:focus::before {
			width: 100%;
		}

		${hover(() => css`
			cursor: pointer;

			&:hover::before {
				width: 100%;
			}
		`)}
	`}

	${props => props.isHero && css`
		padding-right: ${gap[8]};
		padding-left: ${gap[8]};
		background-color: var(--primaryForeground);
		color: var(--white);
		line-height: 1.7;
		transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

		&:active,
		&:focus {
			background-color: var(--white);
			color: var(--primaryForeground);
		}

		${hover(() => css`
			cursor: pointer;

			&:hover {
				background-color: var(--white);
				color: var(--primaryForeground);
			}
		`)}
	`}
`;

const Breadcrumbs__Body = styled.span<BreadcrumbProps>`
	${props => props.isHero && css`
		padding-right: ${gap[8]};
		padding-left: ${gap[8]};
		background-color: var(--primaryForeground);
		color: var(--white);
		line-height: 1.7;
	`}
`;

export default {
	Breadcrumbs,
	Breadcrumbs__Item,
	Breadcrumbs__Link,
	Breadcrumbs__Body
};
