import React from 'react';
import Head from 'next/head';
import Styled from './index.styled';

export type Breadcrumb = {
	id: string
	url: string
	title: string
	dataGAAction: string
	dataGALabel: string
	dataGACategory: string
};

export type BreadcrumbProps = {
	breadcrumbs: Array<Breadcrumb>
	isHero: boolean
};

function renderItemSchema(breadcrumb: Breadcrumb, index: number) {
	const position = index + 1;

	return {
		'@type': 'ListItem',
		position,
		name: `${breadcrumb.title}`,
		item: `${breadcrumb.url}`,
	};
}

function schema(breadcrumbs) {
	return {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: [breadcrumbs.map(renderItemSchema)],
	};
}

const Breadcrumbs = ({ breadcrumbs, isHero }: BreadcrumbProps) => {
	function renderBreadcrumb(breadcrumb) {
		return (
			<Styled.Breadcrumbs__Item as="li" key={breadcrumb.id} isHero={isHero}>
				<Styled.Breadcrumbs__Link href={breadcrumb.url} isHero={isHero}>
					{breadcrumb.title}
				</Styled.Breadcrumbs__Link>
			</Styled.Breadcrumbs__Item>
		);
	}

	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(schema(breadcrumbs)),
					}}
				/>
			</Head>

			<Styled.Breadcrumbs>
				{breadcrumbs.map(renderBreadcrumb)}
			</Styled.Breadcrumbs>
		</>
	);
};

export default Breadcrumbs;
