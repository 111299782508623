/* eslint-disable jsx-a11y/iframe-has-title */
// TODO: Add a title property
import React from 'react';
import { EmbedWrapper } from './index.styled';

interface YouTubeEmbedProps {
	embedLink: string
}

const YouTubeEmbed = ({ embedLink }: YouTubeEmbedProps) => (
		<EmbedWrapper>
			<iframe
				width="560"
				height="315"
				src={embedLink}
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			 />
		</EmbedWrapper>
	);

export default YouTubeEmbed;
