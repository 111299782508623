import styled, { css } from 'styled-components';
import { Grid } from '@/styles/generic/container';
import { gap, margins, spacing } from '@/styles/settings/spacing';
import { above, below, belowHeight } from '@/styles/tools/media';
import { smBodyLight, smBodyRegular, xlTitleRegular } from '../../atoms/Typography/index.styled';
import breakpoints from '../../../styles/settings/breakpoints';
import aspectRatio from '../../../styles/tools/aspectRatio';

interface HeroProps {
	showBoxShadowForDesktop?: boolean;
	showBoxShadowForMobile: boolean;
}

interface HeroInnerProps {
	iscollage?: boolean;
	hassvg?: boolean;
}

interface HeroResponsiveContainer {
	hassvg?: boolean;
}

const Hero = styled.section<HeroProps>`
	position: relative;
	background-color: var(--primaryBackground);
	overflow: hidden;

	${props =>
		props.showBoxShadowForDesktop &&
		css`
			${above(
				breakpoints[960],
				() => css`
					margin-bottom: ${gap[24]};
					box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
				`
			)}

			${above(
				breakpoints[1440],
				() => css`
					margin-bottom: ${gap[32]};
					box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
				`
			)}
		`}

	${props =>
		props.showBoxShadowForMobile &&
		css`
			${below(
				breakpoints[960],
				() => css`
					margin-bottom: ${gap[16]};
					box-shadow: ${gap[16]} ${gap[16]} 0 0 var(--primaryAccent);
				`
			)}
		`}

	${below(
		breakpoints[960],
		() => css`
			padding-bottom: ${spacing[48]};
		`
	)}
`;

const Hero__Inner = styled(Grid)<HeroInnerProps>`

grid-row-gap: 0vh;

	${below(breakpoints[600], () => css``)}

	${above(
		breakpoints[960],
		() => css`
			padding-bottom: ${spacing[48]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-bottom: ${spacing[48]};
		`
	)}

	${props =>
		props.iscollage &&
		css`
			${above(
				breakpoints[960],
				() => css`
					padding-top: ${spacing[64]};
				`
			)}

			${above(
				breakpoints[1440],
				() => css`
					padding-top: ${spacing[24]};
				`
			)}
		`}

		${props =>
			props.hassvg &&
			css`
				${above(
					breakpoints[960],
					() => css`
						padding-top: ${spacing[64]};
					`
				)}

				${above(
					breakpoints[1440],
					() => css`
						padding-top: ${spacing[80]};
					`
				)}
			`}
`;

const Hero__ResponsiveContainer = styled.div<HeroResponsiveContainer>`
	grid-area: 3 / 1 / 5 / 6;
	position: relative;
	z-index: 1;
	margin-top: -${spacing[64]};
	min-width: 0;

	${above(
		breakpoints[600],
		() => css`
			order: 2;
		`
	)}


	${above(
		breakpoints[600],
		() => css`
			grid-column: 1 / span 7;
			grid-row: 2 / span 1;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 1 / span 5;
			grid-row: 1;
			margin-top: ${gap[0]};
			align-self: center;
		`
	)}

	${props =>
		props.hassvg &&
		css`
			margin-top: -54px;

			${above(
				breakpoints[960],
				() => css`
					grid-area: 1 / 1 / 6 / 6;
				`
			)}
		`}
`;

const Hero__Container = styled.div`
	position: relative;
	left: ${margins[16]};
	grid-area: 1 / 1 / 4 / 5;

	& > picture {
		height: auto;
		padding-top: ${aspectRatio('3:2')};
	}

	${below(
		breakpoints[600],
		() => css`
			left: ${margins[32]};
			grid-column: 1 / -1;
			grid-row: 1 / span 1;
			padding-bottom: ${margins[0]};
		`
	)}

	${below(
		breakpoints[960],
		() => css`
			order: 1;
		`
	)}

	${above(
		breakpoints[600],
		() => css`
			left: ${margins[24]};
			grid-column: 1 / -1;
			grid-row: 1 / span 1;

			& > picture {
				padding-top: ${aspectRatio('16:9')};
			}
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			left: 0;
			grid-column: 7 / -1;
		`
	)}
`;

const Hero__ImageContainer = styled(Hero__Container)`
	${above(
		breakpoints[960],
		() => css`
			margin-right: -${margins[48]};
			margin-bottom: ${gap[24]};

			& > picture {
				box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
			}
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			margin-right: -${margins[80]};
			margin-bottom: ${gap[32]};

			& > picture {
				box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
			}
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			margin-right: ${gap[0]};
		`
	)}
`;

const Hero__CollageContainer = styled(Hero__Container)`
	${below(
		breakpoints[960],
		() => css`
			& div:first-child {
				grid-column: 1 / -1;
			}

			& div:nth-child(n + 2) > picture {
				display: none;
			}
		`
	)}
`;

const Hero__Heading = styled(xlTitleRegular)`
	margin-bottom: ${gap[24]};
	max-width: 85%;

	${above(
		breakpoints[600],
		() => css`
			max-width: 100%;
		`
	)}
`;

const Hero__HeadingHighlight = styled.span`
	color: var(--primaryForeground);

	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;

	${below(
		breakpoints[960],
		() => css`
			background-color: var(--white);
			line-height: 1.7;
			padding-right: ${gap[8]};
			padding-left: ${gap[8]};
		`
	)}
`;

const Hero__Body = styled(smBodyLight)`
	margin-bottom: ${gap[32]};

	${above(
		breakpoints[600],
		() => css`
			max-width: 100;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			max-width: 100%;
			margin-bottom: ${gap[40]};
		`
	)}
`;

const Hero__Info = styled(smBodyRegular)`
	display: grid;
	grid-template-columns: 24px 1fr;
	grid-column-gap: ${gap[16]};
	margin-bottom: ${gap[20]};
	color: var(--primaryForeground);

	svg {
		width: 100%;
		height: auto;
	}

	svg > path {
		fill: currentColor;
	}

	&:last-child {
		margin-bottom: 0;
	}

	${above(
		breakpoints[960],
		() => css`
			grid-template-columns: 32px 1fr;
			grid-column-gap: ${gap[20]};
			margin-bottom: ${gap[32]};
		`
	)}
`;

const Hero__CtaContainer = styled.div`
	display: block;
	height: auto;
	width: 100%;

	${above(
		breakpoints[600],
		() => css`
			margin-right: 30%;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			margin-right: ${gap[0]};
		`
	)}
`;

const Hero__CtaContainerText = styled(smBodyRegular)`
	color: var(--primaryForeground);
	margin-bottom: ${gap[4]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[12]};
		`
	)}
`;

const Hero__BodyHeading = styled(smBodyRegular)`
	margin-bottom: ${gap[16]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[24]};
		`
	)}
`;

const Hero__LinkList = styled.ul`
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: ${gap[16]};
	padding-left: 0;
	list-style-type: none;
`;

const Hero__LinkItem = styled.li``;

const PlayButtonWrapper = styled.div`
	position: absolute;
	width: 48px;
	height: 48px;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);

	${above(
		breakpoints[960],
		() => css`
			width: 80px;
			height: 80px;
		`
	)}
`;

const Hero__SvgContainer = styled(Hero__Container)`
	grid-column: 1 / span 4;
	margin-top: 64px;
	padding: 0;

	${below(
		breakpoints[600],
		() => css`
			order: 2;
		`
	)}

	${above(
		breakpoints[600],
		() => css`
			margin-top: 56px;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			margin-top: 120px;
			margin-bottom: 80px;
			grid-column: 7 / -1;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			margin-right: -${margins[80]};
			margin-bottom: ${gap[32]};

			& > picture {
				box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
			}
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			margin-right: ${gap[0]};
		`
	)}
`;

// Hero__svgContainerInner
const Hero__svgContainerInner = styled.div`
	display: block;
	margin: auto;

	& svg {
		width: 250%;
		max-width: 750px;

		${belowHeight(
			700,
			() => css`
				max-width: 500px;
			`
		)}

		${above(
			breakpoints[600],
			() => css`
				width: 180%;
				max-width: none;
			`
		)}

		${above(
			breakpoints[960],
			() => css`
				width: 210%;
			`
		)}

	}
`;

export default {
	Hero,
	Hero__Inner,
	Hero__ImageContainer,
	Hero__CollageContainer,
	Hero__ResponsiveContainer,
	Hero__Heading,
	Hero__HeadingHighlight,
	Hero__Body,
	Hero__Info,
	Hero__CtaContainer,
	Hero__CtaContainerText,
	Hero__BodyHeading,
	Hero__LinkList,
	Hero__LinkItem,
	PlayButtonWrapper,
	Hero__SvgContainer,
	Hero__svgContainerInner,
};
