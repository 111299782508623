import styled from 'styled-components';

const PlayButton__Button = styled.button`
	border: none;
	height: 100%;
	width: 100%;
	background-color: var(--primaryForeground);
	cursor: pointer;

	&:after {
		content: '';
		display: block;
		margin: auto;
		height: 50%;
		margin-left: 3px;
		background-image: url("data:image/svg+xml,%3Csvg width='15' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.75.25v17.5L14.5 9 .75.25z' fill='%23fff'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
`;

export { PlayButton__Button };
